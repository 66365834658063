import React from "react"
import Layout from "../../components/common/Layout"
import ActionBar from "../../components/actionBar/ActionBar"
import Section from "../../components/common/Section"
import Title from "../../components/common/Title"
import ImageBox from "../../components/common/ImageBox"
import Step from "../../components/Step"
import { formatImageData } from "../../scripts/ImageQuery"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Button from "../../components/common/Button"
import { navigate } from "gatsby"
import imageGif from "../../gifs/backsplash.gif"

const Process = () => {
  const image = formatImageData(
    useStaticQuery(graphql`
      query {
        file(relativePath: { eq: "the-process.jpg" }) {
          childImageSharp {
            gatsbyImageData(formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `).file.childImageSharp,
    "Modern White Kitchen"
  )

  return (
    <Layout>
      <ActionBar />
      <Section>
        <Wrapper>
          <div className="top-content">
            <Title text="We make it easy..." sub="4 steps" />
            <ImageBox
              data={imageGif}
              alt={"Modern kitchen backsplash"}
              eager={true}
              isGif={true}
            />
          </div>

          <div className="steps">
            <Step
              title="Learn"
              number="1"
              content="Learn how our product is crafted and what options you have for your kitchen."
            />
            <Step
              title="Design"
              number="2"
              content="Design a kitchen suited to your lifestyle with a professional interior designer."
            />
            <Step
              title="Build"
              number="3"
              content="Your kitchen is handcrafted in Europe and shipped to your home. We make sure everything arrives securely."
            />
            <Step
              title="Execute"
              number="4"
              content="Our expert team handles the installation. You see your kitchen come to life and enjoy the space you tailored."
            />
          </div>

          <h1 className="catch">It's that simple.</h1>
          <Button
            label="Book free consultation"
            onClick={() => navigate("/form")}
          />
        </Wrapper>
      </Section>
    </Layout>
  )
}

const Wrapper = styled.div`
  margin-top: 3rem;
  .top-content {
    display: flex;
    flex-direction: column;
  }
  .steps {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .catch {
    margin-top: 4rem;
    margin-bottom: 3rem;
  }

  @media screen and (min-width: 760px) {
    .top-content {
      flex-direction: column-reverse;
      gap: 3rem;
      margin-bottom: -2rem;
    }
    .steps {
      display: grid;
      grid-template-columns: auto auto;
      grid-row: auto auto;
      grid-column-gap: 80px;
      grid-row-gap: 20px;
    }
  }
`

export function Head() {
  return <title>Our Process</title>
}

export default Process
