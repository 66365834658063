import React from "react"
import styled from "styled-components"

const Step = ({ title, number, content }) => {
  return (
    <Wrapper>
      <div className="top-bar">
        <h1>{title}</h1>
        <div className="line" />
        <h1>{number}</h1>
      </div>
      <p>{content}</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 3rem;

  .line {
    width: 100%;
    background-color: var(--secondary-color);
    height: 1px;
    margin-inline: 2rem;
  }
  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.6rem;
  }
`
export default Step
